























































































































































































































































































































































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import UploadFile from "@c/upload/UploadFile.vue";
import UploadImage from "@c/upload/UploadImage.vue";
import { IndexStore } from "@/store/module/index";
import FileEcho from '@c/upload/FileEcho.vue';

import {
  GetInstitutionAllList,
  GetApplyState,
  GetApplyDetail,
  PersonageApplyAdd,
  GetApplyRecordList,
} from "@/http/api/InitiationApi";
import { FileDownload2 } from "@/http/api/FileApi"


@Component({
  components: {
    Header,
    UploadFile,
    UploadImage,
    Footer,
    FileEcho
  },
})
export default class Personage extends Vue {
  @Ref() private form!: HTMLDivElement;
  @Ref() private resumeForm!: HTMLDivElement;
  @Ref() private work1Form!: HTMLDivElement;
  @Ref() private work2Form!: HTMLDivElement;
  @Ref() private work3Form!: HTMLDivElement;
  @Ref() private work4Form!: HTMLDivElement;
  private pageLoading: boolean = false;
  private submitLoading: boolean = false;
  private id: string = ""; // 申请ID 退回修改时使用
  private remarks: string = ""; // 退回原因
  private formShow: boolean = false; // 表单是否显示
  private state: number = -1; // 状态 1.待确认 2.已同意 3.已拒绝 4.退回修改 5.再次确认 6.草稿 99.没有状态第一次进来
  private recordList: any[] = []; // 申请记录列表
  private userInfo: any = IndexStore.userInfo;
  private po: any = {
    idCard: '',
    fromRemarks: '',
    sex: '', // 性别
    pictureFile: "", // 一寸照片路径
    instId: "", // 活动参加分支机构ID
    instArr: [], // 关注其他分支机构集合
    birthday: "", // 出生日期
    national: "", // 民族
    politicalLandscape: "", // 政治面貌
    learningExperience: "", // 学历
    technicalTitles: "", // 技术职称
    professional: "", // 专业专长
    workingState: "", // 在职/离退休
    email: "", // 邮箱
    mailingAddress: "", // 通讯地址
    zipCode: "", // 邮编
    isAcademician: 0, // 是否院士 1.中科院 2.工程院 3.其他国家 4.否
    workResult: "", // 主要工作成果、业绩
    bookProveFiles: "", // 著作证明附件集合逗号分隔
    patentProveFiles: "", // 专利证明附件集合逗号分隔
    paperProveFiles: "", // 论文证明附件集合逗号分隔
    resultProveFiles: "", // 业绩证明附件集合逗号分隔
    rewardProveFiles: "", // 奖励证明附件集合逗号分隔
    pdfFile: "", // pdf单文件,单文件
    memberForResumes: [], // 个人工作简历集合
    memberForPapers: [], // 发表论文集合
    memberForBooks: [], // 著作集合
    memberForAccredits: [], // 专利授权集合
    memberForRewards: [], // 奖励荣誉集合
    bookProveInfos: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: 著作证明附件集合 ,
    paperProveInfos: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: 论文证明附件集合 ,
    patentProveInfos: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: 专利证明附件集合 ,
    pdfInfo: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: pdf ,
    rewardProveInfos: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: 奖励证明附件集合 ,
    pictureInfo: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: 用户照片 ,
    resultProveInfos: [
      {
        fileName: "", //string, optional): 文件名 ,
        id: "", //string, optional): 文件id ,
        path: "", //string, optional): 路径 ,
        remark: "", //string, optional): 文件真实名
      },
    ], //: 业绩证明附件集合 ,
  };
  private poRules: any = {
    idCard: [
      { required: true, message: "请输入身份证号", trigger: "blur" },
    ],
    pictureFile: [
      { required: true, message: "请上传一寸照片", trigger: "blur" },
    ],
    instId: [
      { required: true, message: "请选择活动参加分支机构", trigger: "blur" },
    ],
    sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
    birthday: [{ required: true, message: "请输入出生年月", trigger: "blur" }],
    national: [{ required: true, message: "请输入民族", trigger: "blur" }],
    politicalLandscape: [
      { required: true, message: "请输入政治面貌", trigger: "blur" },
    ],
    learningExperience: [
      { required: true, message: "请输入学历", trigger: "blur" },
    ],
    technicalTitles: [
      { required: true, message: "请输入技术职称", trigger: "blur" },
    ],
    professional: [
      { required: true, message: "请输入专业专长", trigger: "blur" },
    ],
    workingState: [
      { required: true, message: "请输入在职/离退休", trigger: "blur" },
    ],
    email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" }],
    mailingAddress: [
      { required: true, message: "请输入通讯地址", trigger: "blur" },
    ],
    zipCode: [{ required: true, message: "请输入邮编", trigger: "blur" }],
    isAcademician: [
      { required: true, message: "请选择是否院士", trigger: "blur" },
    ],
    workResult: [
      { required: true, message: "请输入主要工作成果、业绩", trigger: "blur" },
    ],
    resultProveFiles: [
      {
        required: true,
        message: "请上传工作成果、业绩证明材料",
        trigger: "blur",
      },
    ],
    pdfFile: [
      {
        required: true,
        message: "请上传签字及加盖公章后的pdf文件",
        trigger: "blur",
      },
    ],
  };
  private instList: any = [];
  private instList1: any = [];
  // 工作简历
  private resume: any = {
    show: false,
    setIndex: -1,
    formData: {
      time: "",
      unit: "",
      position: "",
    },
    rules: {
      time: [{ required: true, message: "请输入起始年月", trigger: "blur" }],
      unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
      position: [{ required: true, message: "请输入职务", trigger: "blur" }],
    },
  };
  // 论文
  private work1: any = {
    show: false,
    setIndex: -1,
    formData: {
      title: "",
      journalName: "",
      volumeIssue: "",
      ranking: "",
    },
    rules: {
      title: [{ required: true, message: "请输入论文题目", trigger: "blur" }],
      journalName: [
        { required: true, message: "请输入期刊名称", trigger: "blur" },
      ],
      volumeIssue: [
        { required: true, message: "请输入年卷期号", trigger: "blur" },
      ],
      ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
    },
  };
  // 著作
  private work2: any = {
    show: false,
    list: [],
    setIndex: -1,
    formData: {
      bookName: "",
      publicationDate: "",
      press: "",
      ranking: "",
    },
    rules: {
      bookName: [
        { required: true, message: "请输入著作名称", trigger: "blur" },
      ],
      publicationDate: [
        { required: true, message: "请输入出版时间", trigger: "blur" },
      ],
      press: [{ required: true, message: "请输入出版社", trigger: "blur" }],
      ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
    },
  };
  // 专利授权
  private work3: any = {
    show: false,
    list: [],
    setIndex: -1,
    formData: {
      category: "",
      patentName: "",
      patentNo: "",
      accreditDate: "",
      ranking: "",
    },
    rules: {
      category: [
        { required: true, message: "请输入专利类别", trigger: "blur" },
      ],
      patentName: [
        { required: true, message: "请输入专利名称", trigger: "blur" },
      ],
      patentNo: [{ required: true, message: "请输入专利号", trigger: "blur" }],
      accreditDate: [
        { required: true, message: "请输入授权时间", trigger: "blur" },
      ],
      ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
    },
  };
  // 奖励、荣誉
  private work4: any = {
    show: false,
    list: [],
    setIndex: -1,
    formData: {
      rewardName: "",
      time: "",
      prizeUnit: "",
      ranking: "",
    },
    rules: {
      rewardName: [
        { required: true, message: "请输入奖励名称", trigger: "blur" },
      ],
      time: [{ required: true, message: "请输入获奖时间", trigger: "blur" }],
      prizeUnit: [
        { required: true, message: "请输入授奖单位", trigger: "blur" },
      ],
      ranking: [{ required: true, message: "请输入本人排名", trigger: "blur" }],
    },
  };
  private uploadImageConfig: any = {
    limit: 1,
    callback: "",
  };
  private uploadFileConfig1: any = {
    limit: 3,
    callback: [],
  };
  private uploadFileConfig2: any = {
    limit: 3,
    callback: [],
  };
  private uploadFileConfig3: any = {
    limit: 3,
    callback: [],
  };
  private uploadFileConfig4: any = {
    limit: 3,
    callback: [],
  };
  private uploadFileConfig5: any = {
    limit: 3,
    callback: [],
  };
  private uploadFileConfig6: any = {
    limit: 1,
    callback: [],
  };
  private created() {
    this.getUserInfoFun();
    this.getInstitutionList();
    this.getState();
  }
  private download() {
    const parmas = JSON.parse(JSON.stringify(this.po));
    parmas.nikeName = this.userInfo.nikeName
    parmas.mobile = this.userInfo.mobile
    parmas.idCard = this.po.idCard
    parmas.position = this.userInfo.position
    parmas.unitName = this.userInfo.unitName
    parmas.memberInstitutions = [];
    
    parmas.focusOrgNames = ''
    parmas.joinOrgName = ''
    if (parmas.isAcademician == 1) {
      parmas.isAcademician = '中科院 '
    } else if (parmas.isAcademician == 2) {
      parmas.isAcademician = '工程院 '
    } else if (parmas.isAcademician == 3) {
      parmas.isAcademician = '其他国家 '
    } else if (parmas.isAcademician == 4) {
      parmas.isAcademician = '否'
    }

    if (parmas.sex == 2) {
      parmas.sex = '男'
    } else if (parmas.sex == 1) {
      parmas.sex = '女'
    }
    
    // if (this.addForm1.unitPreservePo.embranchmentId) {
    //   this.embranchmentListqt.forEach(item => {
    //     if (item.institutionsId == this.addForm1.unitPreservePo.embranchmentId) {
    //       parmas.joinOrgName = item.nickName
    //     }
    //   })
    // }
    // if (this.addForm1.unitPreservePo.embranchmentIds.length > 0) {
    //   const arr = []
    //   this.addForm1.unitPreservePo.embranchmentIds.forEach(item => {
    //     this.embranchmentListqt.forEach(items => {
    //       if (items.institutionsId == item) {
    //         arr.push(items.nickName)
    //       }
    //     })
    //   })
    //   parmas.focusOrgNames = arr.join()
    // }


    if (this.po.instId) {
      this.instList.forEach((item: any) => {
        if (item.institutionsId == this.po.instId) {
          parmas.joinOrgName = item.nickName
        }
      })
    }
    if (this.po.instArr.length > 0) {
      const arr: any = []
      this.po.instArr.forEach((ele: any) => {
        this.instList1.forEach((eles: any) => {
          if (eles.institutionsId == ele) {
            arr.push(eles.nickName)
          }
        })
      });
      parmas.focusOrgNames = arr.join()
    }
    
    // parmas.birthday = new Date(parmas.birthday);
    FileDownload2("/member/web/pdf/member/person", parmas)
  }
  // 提交+保存
  private submit(flag: number): void {
    console.log(this.po.pictureFile)
    console.log(this.po.pdfFile)
    this.$confirm(
      `确定要${flag === 1 ? "保存" : "提交"}申请表吗？`,
      "确认提交",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    ).then(() => {
      const parmas = this.po;
      parmas.memberInstitutions = [];
      if (this.po.instId) {
        parmas.memberInstitutions.push({
          type: 1,
          institutionsId: this.po.instId,
        });
      }
      this.po.instArr.forEach((ele: string) => {
        parmas.memberInstitutions.push({
          type: 2,
          institutionsId: ele,
        });
      });
      // parmas.birthday = new Date(parmas.birthday);
      parmas.isDraft = flag;
      if (this.id) {
        parmas.id = this.id;
      }
      if (flag !== 2) {
        this.submitLoading = true;
        PersonageApplyAdd(parmas).then((res) => {
          this.submitLoading = false;
          if (res.code === "000") {
            this.getState();
            this.$message.success("保存成功");
          }
        });
        console.log(this.po.pictureFile);

      } else {
        (this.form as any).validate((bool: boolean) => {
          if (bool) {
            this.submitLoading = true;
            PersonageApplyAdd(parmas).then((res) => {
              this.submitLoading = false;
              if (res.code === "000") {
                this.$message.success("成功");
                this.getState();
              }
            });
          } else {
            this.$message.warning('必填项不能为空')
          }
        });
      }
    });
  }
  // 文件
  private UploadFileFun1(val: any) {
    this.po.resultProveFiles = val;
  }
  private UploadFileFun2(val: any) {
    this.po.paperProveFiles = val;
  }
  private UploadFileFun3(val: any) {
    this.po.bookProveFiles = val;
  }
  private UploadFileFun4(val: any) {
    this.po.patentProveFiles = val;
  }
  private UploadFileFun5(val: any) {
    this.po.rewardProveFiles = val;
  }
  private UploadFileFun6(val: any) {
    this.po.pdfFile = val;
  }
  private UploadImageFun(val: any) {
    console.log(val, 'images')
    this.po.pictureFile = val;

  }
  // 监听活动参加分支机构:
  private instIdChange(e: string) {
    const arr: any = [];
    this.instList.forEach((element: any) => {
      if (element.institutionsId !== e) {
        arr.push({
          institutionsId: element.institutionsId,
          nickName: element.nickName,
        });
      }
    });
    this.instList1 = arr;
    this.po.instArr = [];
  }
  // 点击工作简历item
  private resumeItemClick(flag: number, index: number): void {
    if (flag === 1) {
      // 编辑
      const obj: any = this.po.memberForResumes[index];
      this.resume.formData.time = obj.time;
      this.resume.formData.unit = obj.unit;
      this.resume.formData.position = obj.position;
      this.resume.setIndex = index;
      this.resume.show = true;
    } else {
      // 删除
      this.$confirm(`确定要删除该条工作简历吗？`, "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.po.memberForResumes.splice(index, 1);
        this.$message.success("删除成功");
      });
    }
  }
  // 提交工作经历
  private resumeSubmit() {
    (this.resumeForm as any).validate((bool: boolean) => {
      if (bool) {
        if (this.resume.setIndex !== -1) {
          this.po.memberForResumes[this.resume.setIndex].time =
            this.resume.formData.time;
          this.po.memberForResumes[this.resume.setIndex].unit =
            this.resume.formData.unit;
          this.po.memberForResumes[this.resume.setIndex].position =
            this.resume.formData.position;
        } else {
          this.po.memberForResumes.push({
            time: this.resume.formData.time,
            unit: this.resume.formData.unit,
            position: this.resume.formData.position,
          });
        }
        this.resumeClear();
      }
    });
  }
  // 关闭工作简历弹窗
  private resumeClear(): void {
    this.resume.formData = {
      time: "",
      unit: "",
      position: "",
    };
    this.resume.setIndex = -1;
    this.resume.show = false;
  }
  // 打开论文弹窗
  private work1DialogShow() {
    if (this.po.memberForPapers.length >= 3) {
      this.$message.warning("最多填写三条数据");
    } else {
      this.work1.show = true;
    }
  }
  // 点击论文item
  private work1ItemClick(flag: number, index: number): void {
    if (flag === 1) {
      // 编辑
      const obj: any = this.po.memberForPapers[index];
      this.work1.formData.title = obj.title;
      this.work1.formData.journalName = obj.journalName;
      this.work1.formData.volumeIssue = obj.volumeIssue;
      this.work1.formData.ranking = obj.ranking;
      this.work1.setIndex = index;
      this.work1.show = true;
    } else {
      // 删除
      this.$confirm(`确定要删除该条论文吗？`, "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.po.memberForPapers.splice(index, 1);
        this.$message.success("删除成功");
      });
    }
  }
  // 提交论文
  private work1Submit() {
    (this.work1Form as any).validate((bool: boolean) => {
      if (bool) {
        if (this.work1.setIndex !== -1) {
          this.po.memberForPapers[this.work1.setIndex].title =
            this.work1.formData.title;
          this.po.memberForPapers[this.work1.setIndex].journalName =
            this.work1.formData.journalName;
          this.po.memberForPapers[this.work1.setIndex].volumeIssue =
            this.work1.formData.volumeIssue;
          this.po.memberForPapers[this.work1.setIndex].ranking =
            this.work1.formData.ranking;
        } else {
          this.po.memberForPapers.push({
            title: this.work1.formData.title,
            journalName: this.work1.formData.journalName,
            volumeIssue: this.work1.formData.volumeIssue,
            ranking: this.work1.formData.ranking,
          });
        }
        this.work1Clear();
      }
    });
  }
  // 关闭论文弹窗
  private work1Clear(): void {
    this.work1.formData = {
      title: "",
      journalName: "",
      volumeIssue: "",
      ranking: "",
    };
    this.work1.setIndex = -1;
    this.work1.show = false;
  }
  // 打开著作弹窗
  private work2DialogShow() {
    if (this.po.memberForBooks.length >= 3) {
      this.$message.warning("最多填写三条数据");
    } else {
      this.work2.show = true;
    }
  }
  // 点击著作item
  private work2ItemClick(flag: number, index: number): void {
    if (flag === 1) {
      // 编辑
      const obj: any = this.po.memberForBooks[index];
      this.work2.formData.bookName = obj.bookName;
      this.work2.formData.publicationDate = obj.publicationDate;
      this.work2.formData.press = obj.press;
      this.work2.formData.ranking = obj.ranking;
      this.work2.setIndex = index;
      this.work2.show = true;
    } else {
      // 删除
      this.$confirm(`确定要删除该条著作吗？`, "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.po.memberForBooks.splice(index, 1);
        this.$message.success("删除成功");
      });
    }
  }
  // 提交著作
  private work2Submit() {
    (this.work2Form as any).validate((bool: boolean) => {
      if (bool) {
        if (this.work2.setIndex !== -1) {
          this.po.memberForBooks[this.work2.setIndex].bookName =
            this.work2.formData.bookName;
          this.po.memberForBooks[this.work2.setIndex].publicationDate =
            this.work2.formData.publicationDate;
          this.po.memberForBooks[this.work2.setIndex].press =
            this.work2.formData.press;
          this.po.memberForBooks[this.work2.setIndex].ranking =
            this.work2.formData.ranking;
        } else {
          this.po.memberForBooks.push({
            bookName: this.work2.formData.bookName,
            publicationDate: this.work2.formData.publicationDate,
            press: this.work2.formData.press,
            ranking: this.work2.formData.ranking,
          });
        }
        this.work2Clear();
      }
    });
  }
  // 关闭著作弹窗
  private work2Clear(): void {
    this.work2.formData = {
      bookName: "",
      publicationDate: "",
      press: "",
      ranking: "",
    };
    this.work2.setIndex = -1;
    this.work2.show = false;
  }
  // 打开专利授权弹窗
  private work3DialogShow() {
    if (this.po.memberForAccredits.length >= 3) {
      this.$message.warning("最多填写三条数据");
    } else {
      this.work3.show = true;
    }
  }
  // 点击专利授权item
  private work3ItemClick(flag: number, index: number): void {
    if (flag === 1) {
      // 编辑
      const obj: any = this.po.memberForAccredits[index];
      this.work3.formData.category = obj.category;
      this.work3.formData.patentName = obj.patentName;
      this.work3.formData.patentNo = obj.patentNo;
      this.work3.formData.accreditDate = obj.accreditDate;
      this.work3.formData.ranking = obj.ranking;
      this.work3.setIndex = index;
      this.work3.show = true;
    } else {
      // 删除
      this.$confirm(`确定要删除该条专利授权吗？`, "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.po.memberForAccredits.splice(index, 1);
        this.$message.success("删除成功");
      });
    }
  }
  // 提交专利授权
  private work3Submit() {
    (this.work3Form as any).validate((bool: boolean) => {
      if (bool) {
        if (this.work3.setIndex !== -1) {
          this.po.memberForAccredits[this.work3.setIndex].category =
            this.work3.formData.category;
          this.po.memberForAccredits[this.work3.setIndex].patentName =
            this.work3.formData.patentName;
          this.po.memberForAccredits[this.work3.setIndex].patentNo =
            this.work3.formData.patentNo;
          this.po.memberForAccredits[this.work3.setIndex].accreditDate =
            this.work3.formData.accreditDate;
          this.po.memberForAccredits[this.work3.setIndex].ranking =
            this.work3.formData.ranking;
        } else {
          this.po.memberForAccredits.push({
            category: this.work3.formData.category,
            patentName: this.work3.formData.patentName,
            patentNo: this.work3.formData.patentNo,
            accreditDate: this.work3.formData.accreditDate,
            ranking: this.work3.formData.ranking,
          });
        }
        this.work3Clear();
      }
    });
  }
  // 关闭专利授权弹窗
  private work3Clear(): void {
    this.work3.formData = {
      category: "",
      patentName: "",
      patentNo: "",
      accreditDate: "",
      ranking: "",
    };
    this.work3.setIndex = -1;
    this.work3.show = false;
  }
  // 打开专奖励、荣誉弹窗
  private work4DialogShow() {
    if (this.po.memberForRewards.length >= 3) {
      this.$message.warning("最多填写三条数据");
    } else {
      this.work4.show = true;
    }
  }
  // 点击奖励、荣誉item
  private work4ItemClick(flag: number, index: number): void {
    if (flag === 1) {
      // 编辑
      const obj: any = this.po.memberForRewards[index];
      this.work4.formData.rewardName = obj.rewardName;
      this.work4.formData.time = obj.time;
      this.work4.formData.prizeUnit = obj.prizeUnit;
      this.work4.formData.ranking = obj.ranking;
      this.work4.setIndex = index;
      this.work4.show = true;
    } else {
      // 删除
      this.$confirm(`确定要删除该条奖励、荣誉吗？`, "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.po.memberForRewards.splice(index, 1);
        this.$message.success("删除成功");
      });
    }
  }
  // 提交奖励、荣誉
  private work4Submit() {
    (this.work4Form as any).validate((bool: boolean) => {
      if (bool) {
        if (this.work4.setIndex !== -1) {
          this.po.memberForRewards[this.work4.setIndex].rewardName =
            this.work4.formData.rewardName;
          this.po.memberForRewards[this.work4.setIndex].time =
            this.work4.formData.time;
          this.po.memberForRewards[this.work4.setIndex].prizeUnit =
            this.work4.formData.prizeUnit;
          this.po.memberForRewards[this.work4.setIndex].ranking =
            this.work4.formData.ranking;
        } else {
          this.po.memberForRewards.push({
            rewardName: this.work4.formData.rewardName,
            time: this.work4.formData.time,
            prizeUnit: this.work4.formData.prizeUnit,
            ranking: this.work4.formData.ranking,
          });
        }
        this.work4Clear();
      }
    });
  }
  // 关闭奖励、荣誉弹窗
  private work4Clear(): void {
    this.work4.formData = {
      rewardName: "",
      time: "",
      prizeUnit: "",
      ranking: "",
    };
    this.work4.setIndex = -1;
    this.work4.show = false;
  }
  // 获取用户信息
  private getUserInfoFun(): void {
    const that = this;
    if (!IndexStore.userInfo) {
      setTimeout(() => {
        that.getUserInfoFun();
      }, 1000);
    } else {
      this.userInfo = IndexStore.userInfo;
      this.po.idCard = IndexStore.userInfo.idCard
    }
  }
  // 获取所有分支机构
  private getInstitutionList(): void {
    this.pageLoading = true;
    GetInstitutionAllList().then((res) => {
      this.pageLoading = false;
      if (res.code === "000") {
        this.instList = res.result;
      }
    });
  }
  // 获取申请表状态
  private getState(): void {
    this.pageLoading = true;
    GetApplyState().then((res) => {
      this.pageLoading = false;
      if (res.code === "000") {
        if (res.result) {
          this.id = res.result.id;
          this.remarks = res.result.remarks;
          this.state = res.result.state;
          if (res.result.state === 6) {
            this.formShow = true;
            console.log('重新填写')

            this.getDetail(res.result.id);
          } else {
            this.formShow = false;
            this.getRecordList();
          }
        } else {
          this.state = 99;
        }
        if (res.result && res.result.state === 3) {
          this.id = ''
        }
      }
    });
  }
  // 点击重新修改或重新填写
  private again(flag: number): void {
    // 1 退回修改  2 重新填写
    console.log('退回修改')

    this.formShow = true;
    if (flag === 1) {
      this.getDetail(this.id);
    }
  }
  // 获取申请表详情
  private getDetail(id: string): void {
    this.pageLoading = true;
    GetApplyDetail({ id }).then((res) => {
      this.pageLoading = false;
      if (res.code === "000") {
        const data = res.result;
        let imgArr: string[] = [];
        if(data.pictureInfo) {
          data.pictureInfo.forEach((val: any, ind: number) => {
            imgArr.push(val.path);
          });
          this.uploadImageConfig.callback = imgArr.join(",");

        }
        if (data.idCard) {
          this.po.idCard = data.idCard
        }
        this.po.sex = data.sex;
        this.po.pictureFile = data.pictureFile;
        this.po.birthday = data.birthday;
        this.po.fromRemarks = data.fromRemarks;
        this.po.national = data.national;
        this.po.politicalLandscape = data.politicalLandscape;
        this.po.learningExperience = data.learningExperience;
        this.po.technicalTitles = data.technicalTitles;
        this.po.professional = data.professional;
        this.po.workingState = data.workingState;
        this.po.email = data.email;
        this.po.mailingAddress = data.mailingAddress;
        this.po.zipCode = data.zipCode;
        this.po.isAcademician = data.isAcademician;
        this.po.workResult = data.workResult;
        this.po.bookProveFiles = data.bookProveFiles;
        this.po.patentProveFiles = data.patentProveFiles;
        this.po.paperProveFiles = data.paperProveFiles;
        this.po.resultProveFiles = data.resultProveFiles;
        this.po.rewardProveFiles = data.rewardProveFiles;
        this.po.pdfFile = data.pdfFile;
        this.uploadFileConfig1.callback = data.resultProveInfos;
        this.uploadFileConfig6.callback = data.pdfInfo;
        if (data.paperProveInfos) {
          this.uploadFileConfig2.callback = data.paperProveInfos;
        }
        if (data.bookProveInfos) {
          this.uploadFileConfig3.callback = data.bookProveInfos;
        }
        if (data.patentProveInfos) {
          this.uploadFileConfig4.callback = data.patentProveInfos;
        }
        if (data.rewardProveInfos) {
          this.uploadFileConfig5.callback = data.rewardProveInfos;
        }
        this.po.memberForResumes = data.memberForResumes;
        this.po.memberForPapers = data.memberForPapers;
        this.po.memberForBooks = data.memberForBooks;
        this.po.memberForAccredits = data.memberForAccredits;
        this.po.memberForRewards = data.memberForRewards;
        let instId: string = "";
        const instArr: string[] = [];
        data.memberInstitutions.forEach((val: any, ind: number) => {
          if (val.type === "1") {
            instId = val.institutionsId;
          } else {
            instArr.push(val.institutionsId);
          }
        });
        this.po.instId = instId;
        this.instIdChange(instId);
        this.setInstArr(instArr);
        // this.po.instArr = instArr;
      }
    });
  }
  private setInstArr(arr: any) {
    const that = this;
    setTimeout(() => {
      that.po.instArr = arr;
    }, 1000);
  }
  // 获取申请记录历史表
  private getRecordList(): void {
    GetApplyRecordList({
      pageNo: 1,
      pageSize: 10000,
    }).then((res) => {
      if (res.code === "000") {
        this.recordList = res.result.records;
      }
    });
  }
}
